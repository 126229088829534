/*
 * @Author: Alan
 * @Date: 2023-07-04 12:27:14
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-25 14:27:41
 * @Description: 欢迎弹窗
 * @FilePath: /F1-M1-WEB-Code/components/WelcomeBanne/index.js
 */
import React from "react";
import { Modal, Icon, message, Button } from "antd";
import ImageWithFallback from "@/ImageWithFallback/";
import { isWebPSupported } from "$ACTIONS/helper";
message.config({
    duration: 2,
    maxCount: 1,
});

class WelcomeBanne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    componentDidMount() {
        if (!localStorage.getItem("WelcomeBanne")) {
            this.setState({
                visible: true,
            });
        } else {
            this.setState({
                visible: false,
            });
        }
    }

    render() {
        return (
            <div>
                <Modal
                    title=""
                    closable={false}
                    className="modal-pubilc WelcomeBanne"
                    zIndex={2001}
                    visible={this.state.visible}
                    onOk={() => {
                        this.setState({
                            visible: false,
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            visible: false,
                        });
                    }}
                    width={600}
                    footer={null}
                    centered={true}
                    maskClosable={false}
                >
                    <ImageWithFallback
                        src={`/cn/img/home/WelcomeBanner${
                            isWebPSupported() ? ".webp" : "jpg"
                        }`}
                        priority
                        width={600}
                        height={400}
                        onClick={() => {}}
                        alt={"F88全新升级"}
                        local={true}
                    />
                    <center>
                        <Button
                            size="large"
                            type="primary"
                            style={{
                                width: "300px",
                                marginBottom: "30px",
                                marginTop: "30px",
                            }}
                            onClick={() => {
                                this.setState({
                                    visible: false,
                                });
                                localStorage.setItem("WelcomeBanne", true);
                            }}
                        >
                            立即体验
                        </Button>
                    </center>
                </Modal>
            </div>
        );
    }
}

export default WelcomeBanne;
