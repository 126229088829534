/*
 * @Author: Alan
 * @Date: 2022-03-07 11:49:02
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-06 00:09:32
 * @Description: Banner
 * @FilePath: /F1-M1-WEB-Code/components/Banner/img.js
 */
import React from "react";
import Router from "next/router";
import LaunchGame from "@/Games/openGame";
import Image from "next/image";
import { getQueryVariable } from '$ACTIONS/helper'
import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from '$ACTIONS/TlcRequest';
import { connect } from 'react-redux';
import { changeTabAction } from "../../store/thunk/promotionThunk";
// import LazyLoad from 'react-lazyload';

class Banner extends React.Component {
    constructor() {
        super();
        this.state = {
            WalletCloseBanner: false,
        };
    }
    componentDidMount() {
        this.setState({
            WalletCloseBanner: sessionStorage.getItem("WalletCloseBanner"),
        });
    }

    CloseBanner() {
        sessionStorage.setItem("WalletCloseBanner", true);
        this.setState({
            WalletCloseBanner: true,
        });
    }

    /**
     * @description: 轮播图跳转动作
     * @param {*} data
     * @return {*}
     */

    async BannerAction(data) {
        let item = data.action;
        let isLogin = localStorage.getItem("access_token");
        if (!item.actionId || item.actionId == 0) {
            return;
        }
        console.log("----------------->", item);
        switch (item.actionId) {
            case 28:
                // if (item.url == '/cn/mobile/event_MidAutumn2022') {
                // 	Pushgtagdata('Engagement_Event', 'Click', 'Enter_MidAutumn2022​');
                // }

                // if (item.url == '/event/WC2022') {
                // 	Router.push('/event_WC2022');
                // 	Pushgtagdata('Engagement_Event', 'Click', 'Enter_WCPage2022​​');
                // 	return;
                // }

                // if (item.url == '/nationalday2022') {
                // 	Router.push('/event_nationalday2022');
                // 	Pushgtagdata('Engagement_Event', 'Click', 'Enter_ NationalDay2022​');
                // 	return;
                // }

                location.href = item.url;
                //window.open(item.url, '_blank');
                break;
            case 29:
                // if (!localStorage.getItem("access_token")) {
                //     global.goUserSign("1");
                //     return;
                // }
                if (data.category == "worldcup_main") {
                    Pushgtagdata(
                        "Promo Nav​",
                        "View",
                        item.ID + "_PromoTnC_WCPage2022​​"
                    );
                }
                const res = await get(ApiPort.CMSPromotionDetail + `id=${item.ID}`);
                Router.push(`/cn/Promotions?id=${item.ID}&jumpfrom=BANNER`);
                if (res.promoMainCategory === "Daily Deal") {
                    this.props.changeTab && this.props.changeTab("4");
                } else {
                    this.props.changeTab && this.props.changeTab("1");
                };
                break;
            case 30:
                Router.push("/cn/Sponsor");
                break;
            case 31:
                if (!isLogin) {
                    global.goUserSign(getQueryVariable("isAgent") === "1" ? "2" : "1");
                    return;
                }
                global.showDialog({ key: 'wallet:{"type": "deposit"}' });
                break;
            case 32:
            case 34:
                let GameData = {
                    provider: item.cgmsVendorCode,
                    gameId: item.gameId,
                    type: "Game",
                };

                /* if (item.cgmsVendorCode == "VTG") {
                    Router.push(
                        "/cn/Games/Sportsbook/lobby/?name=VTG&type=&id=429"
                    );
                    return;
                } */
                if (item.cgmsVendorCode === "WEC") {
                    Router.push(
                        "/cn/Games/LiveCasino/lobby/?name=WEC&type=&id=0"
                    );
                    return;
                }

                if (item.cgmsVendorCode === "EVO") {
                    Router.push(
                        "/cn/Games/LiveCasino/lobby/?name=EVO&type=&id=0"
                    );
                    return;
                }

                if (item.cgmsVendorCode === 'CQG') {
					Router.push('/cn/Games/Slot/lobby/?name=CQG&type=&id=0');
					return;
				}

                if (item.cgmsVendorCode === 'SWF') {
					Router.push('/cn/Games/Slot/lobby/?name=SWF&type=&id=0');
					return;
				}

                if (item.cgmsVendorCode === 'AG') {
					Router.push('/cn/Games/LiveCasino/lobby/?name=AG&type=&id=0');
					return;
				}

                if (item.cgmsVendorCode === 'DGG') {
                    Pushgtagdata('Home', 'Click Feature Banner', 'Home_C_FeatureBanner', 'Home_C_FeatureBanner_ActivityName', data.title);
					Router.push('/cn/Games/LiveCasino/lobby/?name=DGG&type=&id=0');
					return;
				}

                if (item.cgmsVendorCode === 'JBP') {
					Router.push('/cn/Games/P2P/lobby/?name=JBP&type=&id=0');
					return;
				}

                if (item.cgmsVendorCode === 'KYS') {
					Router.push('/cn/Games/P2P/lobby/?name=KYS&type=&id=0');
					return;
				}

                if (item.cgmsVendorCode === 'YBP') {
					Router.push('/cn/Games/P2P/lobby/?name=YBP&type=&id=0');
					return;
				}

                if(item.cgmsVendorCode === 'SPR'){
                    this.QuickStartGame.OpenSPR();
					return;
				}

                if (
                    item.launchMode == "game_id" ||
                    item.launchMode == "web_view"
                ) {
                    this.QuickStartGame.openGame(GameData);
                    return;
                }

                if (item.launchMode == "lobby") {
                    Router.push("/cn/Games/" + item.cgmsVendorCode);
                    return;
                }

                break;
            case 33:
                PopUpLiveChat();
                break;
            case 202211:
                Pushgtagdata(
                    "Game​",
                    "View",
                    item.matchInfo.match_id + "_IMSP_WCPage2022​​"
                );
                Router.push(
                    `/sbtwo/sports-im/detail/?sid=1&eid=${
                        item.matchInfo.event_id
                    }&lid=${30455}&OE=false`
                );
                break;

            default:
                break;
        }
    }

    render() {
        const { WalletCloseBanner } = this.state;
        const { item, type, height, width, imgType, bannerFlag, bannerList } =
            this.props;
        return (
            <React.Fragment>
                {!WalletCloseBanner && (
                    <div
                        className="BannerBox"
                        style={{ padding: !type || type == "home" ? "0" : "" }}
                    >
                        <React.Fragment>
                            {type == "home" ? (
                                <Image
                                    src={item.cmsImageUrl}
                                    width={width}
                                    height={height}
                                    style={{
                                        display: "block",
                                    }}
                                    onClick={() => {
                                        this.BannerAction(item);
                                        if (imgType != "CenterBanner") {
                                            Pushgtagdata(
                                                `Banner`,
                                                "Click",
                                                `${item.action.ID}_${item.title}_Home`
                                            );
                                        } else {
                                            Pushgtagdata(
                                                `Banner`,
                                                "Click",
                                                `${item.action.actionName}_Feature_Home`
                                            );
                                        }
                                    }}
                                    alt={item ? item.title : ""}
                                    priority
                                />
                            ) : (
                                <React.Fragment>
                                    <Image
                                        src={item ? item.cmsImageUrl : ""}
                                        priority
                                        width={width ? width : 360}
                                        height={height ? height : 70}
                                        onClick={() => {
                                            this.BannerAction(item);
                                            // Pushgtagdata(
                                            //     `Banner`,
                                            //     "Click",
                                            //     `${item.action.actionName}_${item.category}Page`
                                            // );
                                        }}
                                        alt={item ? item.title : ""}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </div>
                )}
                <LaunchGame
                    QuickStartGame={true}
                    OnRef={(QuickStartGame) =>
                        (this.QuickStartGame = QuickStartGame)
                    }
                />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = function(dispatch) {
	return {
        changeTab: (i) => {
            dispatch(changeTabAction(i));
        },
	};
};

export default connect(null, mapDispatchToProps)(Banner);
